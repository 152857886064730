/* eslint-disable jsx-a11y/heading-has-content */
import classNames from 'classnames';
import * as React from 'react';

export interface SkeletonTitleProps {
  prefixCls?: string;
  className?: string;
  style?: React.CSSProperties;
  width?: number | string;
  children?: React.ReactNode;
}

const Title: React.FC<SkeletonTitleProps> = ({
  prefixCls,
  className,
  width,
  style,
  children,
}) => (
  <h3 className={classNames(prefixCls, className)} style={{ width, ...style }}>
    {children}
  </h3>
);

export default Title;
