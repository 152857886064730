interface ImgResponsiveProps {
  className?: string;
  PicClassName?: string;
  alt: string;
  src?: string;
  sourceImgSm?: string;
  sourceImgMd?: string;
  sourceImgLg?: string;
  sourceImgXl?: string;
  wpSourceImg?: string;
  width?: number;
  height?: number;
  loadingLazy?: boolean;
  style?: React.CSSProperties;
}

const ImgResponsive = (props: ImgResponsiveProps): React.JSX.Element => {
  const {
    className = '',
    PicClassName = '',
    alt = '',
    src = '',
    sourceImgSm = '',
    sourceImgMd = '',
    sourceImgLg = '',
    sourceImgXl = '',
    wpSourceImg = '',
    width,
    height,
    loadingLazy,
    style,
  } = props;
  return (
    <picture className={PicClassName}>
      {sourceImgSm ? <source srcSet={sourceImgSm} media="(max-width: 576px)" /> : ''}
      {sourceImgMd ? <source srcSet={sourceImgMd} media="(max-width: 768px)" /> : ''}
      {sourceImgLg ? <source srcSet={sourceImgLg} media="(max-width: 992px)" /> : ''}
      {sourceImgXl ? <source srcSet={sourceImgXl} media="(max-width: 1280px)" /> : ''}
      {wpSourceImg ? <source srcSet={wpSourceImg} /> : ''}
      <img
        src={src}
        alt={alt}
        loading={loadingLazy ? 'lazy' : 'eager'}
        width={width}
        height={height}
        className={className}
        style={{ ...style }}
      />
    </picture>
  );
};

export default ImgResponsive;
