import styled from 'styled-components';

export const StyleskeletonClsLoading = styled.div`
  display: table;
  width: 100%;
  &[class^='skeleton'],
  &[class*=' skeleton'] {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
      Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 14px;
    box-sizing: border-box;
  }
  &[class^='skeleton'] [class^='skeleton'],
  &[class*=' skeleton'] [class^='skeleton'],
  &[class^='skeleton'] [class*=' skeleton'],
  &[class*=' skeleton'] [class*=' skeleton'] {
    box-sizing: border-box;
  }
  &.skeleton-element {
    display: inline-block;
    width: 100%;
    .skeleton-image {
      display: flex;
      align-items: center;
      justify-content: center;
      vertical-align: top;
      background: rgba(0, 0, 0, 0.06);
      border-radius: 4px;
      width: 100%;
      height: 100%;
      min-height: 96px;
      line-height: 96px;
      aspect-ratio: 3 / 2;
      .skeleton-image-svg {
        width: 48px;
        height: 48px;
        line-height: 48px;
        max-width: 192px;
        max-height: 192px;
        .skeleton-image-path {
          fill: #bfbfbf;
        }
      }
      &.skeleton-lg,
      &.skeleton-button-lg {
        border-radius: 40px;
      }
      &.skeleton-lg,
      &.skeleton-avatar-lg {
        width: 40px;
        height: 40px;
        line-height: 40px;
      }
      &.skeleton-sm,
      &.skeleton-button-sm {
        border-radius: 24px;
      }
      &.skeleton-sm,
      &.skeleton-avatar-sm {
        width: 24px;
        height: 24px;
        line-height: 24px;
      }
    }
    .skeleton-button {
      display: inline-block;
      vertical-align: top;
      background: rgba(0, 0, 0, 0.06);
      border-radius: 4px;
      width: 64px;
      min-width: 64px;
      height: 32px;
      line-height: 32px;
      &.skeleton-button-circle {
        width: 32px;
        min-width: 32px;
        border-radius: 50%;
      }
      &.skeleton-button-round {
        border-radius: 32px;
      }
      &.skeleton-lg,
      &.skeleton-button-lg {
        border-radius: 40px;
      }
      &.skeleton-lg,
      &.skeleton-avatar-lg {
        width: 40px;
        height: 40px;
        line-height: 40px;
      }
      &.skeleton-sm,
      &.skeleton-button-sm {
        border-radius: 24px;
      }
      &.skeleton-sm,
      &.skeleton-avatar-sm {
        width: 24px;
        height: 24px;
        line-height: 24px;
      }
    }
    .skeleton-avatar {
      display: inline-block;
      vertical-align: top;
      background: rgba(0, 0, 0, 0.06);
      width: 32px;
      height: 32px;
      line-height: 32px;
      &.skeleton-circle {
        border-radius: 50%;
      }
      &.skeleton-avatar-circle {
        border-radius: 50%;
      }
      &.skeleton-lg,
      &.skeleton-button-lg {
        border-radius: 40px;
      }
      &.skeleton-lg,
      &.skeleton-avatar-lg {
        width: 40px;
        height: 40px;
        line-height: 40px;
      }
      &.skeleton-sm,
      &.skeleton-button-sm {
        border-radius: 24px;
      }
      &.skeleton-sm,
      &.skeleton-avatar-sm {
        width: 24px;
        height: 24px;
        line-height: 24px;
      }
    }
    .skeleton-input {
      display: inline-block;
      vertical-align: top;
      background: rgba(0, 0, 0, 0.06);
      border-radius: 4px;
      width: 160px;
      min-width: 160px;
      height: 32px;
      line-height: 32px;
      &.skeleton-lg,
      &.skeleton-button-lg {
        border-radius: 40px;
      }
      &.skeleton-lg,
      &.skeleton-avatar-lg {
        width: 40px;
        height: 40px;
        line-height: 40px;
      }
      &.skeleton-sm,
      &.skeleton-button-sm {
        border-radius: 24px;
      }
      &.skeleton-sm,
      &.skeleton-avatar-sm {
        width: 24px;
        height: 24px;
        line-height: 24px;
      }
    }
  }
  .skeleton-header {
    display: table-cell;
    padding-inline-end: 16px;
    vertical-align: top;
    .skeleton-avatar {
      display: inline-block;
      vertical-align: top;
      background: rgba(0, 0, 0, 0.06);
      width: 32px;
      height: 32px;
      line-height: 32px;
    }
    .skeleton-avatar-circle {
      border-radius: 50%;
    }
    &.skeleton-lg,
    &.skeleton-button-lg {
      border-radius: 40px;
    }
    &.skeleton-lg,
    &.skeleton-avatar-lg {
      width: 40px;
      height: 40px;
      line-height: 40px;
    }
    &.skeleton-sm,
    &.skeleton-button-sm {
      border-radius: 24px;
    }
    &.skeleton-sm,
    &.skeleton-avatar-sm {
      width: 24px;
      height: 24px;
      line-height: 24px;
    }
  }
  .skeleton-content {
    display: table-cell;
    width: 100%;
    vertical-align: top;
    .skeleton-paragraph {
      padding: 0;
      > li {
        width: 100%;
        height: 16px;
        list-style: none;
        background: rgba(0, 0, 0, 0.06);
        border-radius: 4px;
      }
      > li + li {
        margin-block-start: 16px;
      }
    }
  }
  &.skeleton-active {
    .skeleton-title,
    .skeleton-paragraph > li,
    .skeleton-avatar,
    .skeleton-button,
    .skeleton-input,
    .skeleton-image {
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.06) 25%,
        rgba(0, 0, 0, 0.15) 37%,
        rgba(0, 0, 0, 0.06) 63%
      );
      background-size: 400% 100%;
      animation-name: skeleton-loading;
      animation-duration: 1.4s;
      animation-timing-function: ease;
      animation-iteration-count: infinite;
    }
  }
  @keyframes skeleton-loading {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
  @keyframes scale {
    0% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes loader {
    0% {
      transform: translateX(-150%);
    }
    50% {
      transform: translateX(-60%);
    }
    100% {
      transform: translateX(150%);
    }
  }
`;
