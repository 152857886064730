import styled from 'styled-components';

export const StyleBadge = styled.span`
  font-size: 11px;
  line-height: 17px;
  padding: 2px 8px;
  height: auto;
  &.badge-primary {
    background-color: #dfefff;
    border-color: #dfefff;
    color: #1990ff;
  }
  &.badge-secondary {
    background-color: #ecebf6;
    border-color: #ecebf6;
    color: #7c7a8a;
  }
  &.badge-error {
    background-color: #ffeaed;
    border-color: #ffeaed;
    color: #ff2d49;
  }
  &.badge-warning {
    background-color: #ffefd4;
    border-color: #ffefd4;
    color: #dc8800;
  }
  &.badge-success {
    background-color: #d9f5df;
    border-color: #d9f5df;
    color: #11c739;
  }
  &.badge-info {
    background-color: #7fc1ff;
    border-color: #7fc1ff;
    color: #ffffff;
  }
  &.badge-light {
    background-color: #ffffff;
    border-color: #7c7a8a;
    color: #7c7a8a;
  }
  &.badge-dark {
    background-color: #282732;
    border-color: #282732;
    color: #ffffff;
  }
  &.chip {
    &.chip-primary {
      background-color: #dfefff;
      color: #1990ff;
    }
    &.chip-secondary {
      background-color: #ecebf6;
      color: #7c7a8a;
    }
    &.chip-error {
      background-color: #ffeaed;
      color: #ff2d49;
    }
    &.chip-warning {
      background-color: #ffefd4;
      color: #dc8800;
    }
    &.chip-success {
      background-color: #e9ffee;
      color: #11c739;
    }
    &.chip-info {
      background-color: #dfefff;
      color: #019ef7;
    }
    &.chip-light {
      background-color: #ecebf6;
      color: #7c7a8a;
    }
    &.chip-dark {
      background-color: #282732;
      color: #ffffff;
    }
  }
  &.badge-square {
    border-radius: 0px;
  }
  // #DFDEEA
`;
