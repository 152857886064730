import Link from 'next/link';
import { StyleFooter } from './style';
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
  FaFacebook,
  FaLine,
} from 'react-icons/fa';
import { ExternalLink } from '@/components/elements';

const Footer: React.FC = () => {
  return (
    <footer>
      <StyleFooter className="bg-primary py-8 shadow">
        <div className="mx-auto max-w-screen-3xl px-8 text-white">
          <div className="grid grid-cols-1 gap-8 md:grid-cols-12 md:gap-4">
            <div className="border-b-2 border-white pb-8 text-center md:col-span-5 md:border-b-0 md:border-r-2 md:pb-0 md:text-left lg:md:col-span-4">
              <div className="grid grid-cols-1 gap-8 xl:grid-cols-5">
                <div className="xl:col-span-3">
                  <p className="mb-4 text-5xl">เราอยู่นี่...</p>
                  <p className="text-4xl uppercase">I&apos;m here</p>
                </div>
                <div className="xl:col-span-2">
                  <p className="small mb-2">Social</p>
                  <div className="flex justify-center gap-4 md:justify-start">
                    <p className="footer_icon">
                      <ExternalLink
                        href="https://www.facebook.com/TwinSynergyTH"
                        title="facebook"
                        className="text-primary"
                      >
                        <FaFacebook size={24} />
                      </ExternalLink>
                    </p>
                    <p className="footer_icon">
                      <ExternalLink
                        href="https://lin.ee/8ocMtzc"
                        title="line"
                        className="text-primary"
                      >
                        <FaLine size={24} />
                      </ExternalLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center md:col-span-7 md:text-left lg:md:col-span-8">
              <p className="small mb-2">Address</p>

              <ul className="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3">
                <li className="flex flex-col items-center gap-4 lg:flex-row">
                  <div className="footer_icon">
                    <FaMapMarkerAlt size={24} />
                  </div>
                  <p className="small text-center lg:text-left">
                    35/5 ซอยพระรามเก้า 57/1 (วิเศษสุข 2) แขวงพัฒนาการ เขตสวนหลวง กรุงเทพฯ
                    10250
                  </p>
                </li>
                <li className="flex flex-col items-center gap-4 lg:flex-row">
                  <p className="footer_icon">
                    <FaPhoneAlt size={24} />
                  </p>
                  <p className="small">โทร : 063-789-9059</p>
                </li>
                <li className="flex flex-col items-center gap-4 lg:col-span-2 lg:flex-row xl:col-span-1">
                  <p className="footer_icon">
                    <FaEnvelope size={24} />
                  </p>
                  <p className="small">Email : info[at]twinsynergy.co.th</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </StyleFooter>
      <div className="container mx-auto max-w-screen-3xl p-4">
        <div className="flex gap-2">
          <p className="small">
            <Link href="/privacy" className="hover:underline">
              Privacy Policy |
            </Link>
          </p>
          <p className="small">
            &copy;{new Date().getFullYear().toString()} Twin Synergy Co.,Ltd
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
