import classNames from 'classnames';
import React, { forwardRef, useContext, useEffect, useMemo, useState } from 'react';
import DisabledContext from './DisabledContext';
import { ConfigContext } from './context';
import IconWrapper from './IconWrapper';
import LoadingIcon from './LoadingIcon';

const ButtonTypes = ['default', 'primary', 'dashed', 'link', 'text'] as const;
export type ButtonType = (typeof ButtonTypes)[number];

const ButtonShapes = ['default', 'circle', 'round'] as const;
export type ButtonShape = (typeof ButtonShapes)[number];

const ButtonHTMLTypes = ['submit', 'button', 'reset'] as const;
export type ButtonHTMLType = (typeof ButtonHTMLTypes)[number];

export type SizeType = 'small' | 'middle' | 'large' | undefined;

export function isUnBorderedButtonType(type?: ButtonType) {
  return type === 'text' || type === 'link';
}

export interface BaseButtonProps {
  type?: ButtonType;
  icon?: React.ReactNode;
  shape?: ButtonShape;
  size?: SizeType;
  disabled?: boolean;
  loading?: boolean | { delay?: number };
  prefixCls?: string;
  className?: string;
  rootClassName?: string;
  ghost?: boolean;
  danger?: boolean;
  block?: boolean;
  children?: React.ReactNode;
  [key: `data-${string}`]: string;
  classNames?: { icon: string };
  styles?: { icon: React.CSSProperties };
  isBubbly?: boolean;
}

type MergedHTMLAttributes = Omit<
  React.HTMLAttributes<HTMLElement> &
    React.ButtonHTMLAttributes<HTMLElement> &
    React.AnchorHTMLAttributes<HTMLElement>,
  'type'
>;

export interface ButtonProps extends BaseButtonProps, MergedHTMLAttributes {
  href?: string;
  htmlType?: ButtonHTMLType;
}
type CompoundedComponent = React.ForwardRefExoticComponent<
  ButtonProps & React.RefAttributes<HTMLElement>
> & {
  /** @internal */
  _BUTTON: boolean;
};

type LoadingConfigType = {
  loading: boolean;
  delay: number;
};

function getLoadingConfig(loading: BaseButtonProps['loading']): LoadingConfigType {
  if (typeof loading === 'object' && loading) {
    const delay = loading?.delay;
    const isDelay = !Number.isNaN(delay) && typeof delay === 'number';
    return {
      loading: false,
      delay: isDelay ? delay : 0,
    };
  }

  return {
    loading: !!loading,
    delay: 0,
  };
}

const InternalButton: React.ForwardRefRenderFunction<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps
> = (props, ref) => {
  const {
    loading = false,
    prefixCls = 'btn',
    type = 'default',
    danger,
    shape = 'default',
    size: customizeSize,
    styles,
    disabled: customDisabled,
    className,
    rootClassName,
    children,
    icon,
    ghost = false,
    block = false,
    // React does not recognize the `htmlType` prop on a DOM element. Here we pick it out of `rest`.
    htmlType = 'button',
    classNames: customClassNames,
    style: customStyle = {},
    isBubbly = true,
    ...rest
  } = props;
  const { button } = React.useContext(ConfigContext);

  const disabled = useContext(DisabledContext);
  const mergedDisabled = customDisabled ?? disabled;

  const loadingOrDelay = useMemo<LoadingConfigType>(
    () => getLoadingConfig(loading),
    [loading],
  );

  const [innerLoading, setInnerLoading] = useState<boolean>(loadingOrDelay.loading);

  useEffect(() => {
    let delayTimer: ReturnType<typeof setTimeout> | null = null;
    if (loadingOrDelay.delay > 0) {
      delayTimer = setTimeout(() => {
        delayTimer = null;
        setInnerLoading(true);
      }, loadingOrDelay.delay);
    } else {
      setInnerLoading(loadingOrDelay.loading);
    }

    function cleanupTimer() {
      if (delayTimer) {
        clearTimeout(delayTimer);
        delayTimer = null;
      }
    }

    return cleanupTimer;
  }, [loadingOrDelay]);

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>,
  ) => {
    const { onClick } = props;
    if (innerLoading ?? mergedDisabled) {
      e.preventDefault();
      return;
    }
    (onClick as React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>)?.(e);
  };

  const iconType = innerLoading ? 'loading' : icon;

  const linkButtonRestProps = rest as ButtonProps & { navigate: any };

  const btnCls = classNames(
    prefixCls,
    {
      [`${prefixCls}-${shape}`]: shape !== 'default' && shape !== 'circle' && shape,
      [`${prefixCls}-${shape} rounded-full`]: shape === 'circle',
      [`${prefixCls}-${shape} rounded-none`]: shape === 'default',
      [`${prefixCls}-icon-only`]: !children && children !== 0 && !!iconType,
      [`${prefixCls}-loading`]: innerLoading,
      [`${prefixCls}-two-chinese-chars`]: !innerLoading,
      [`${prefixCls}-block`]: block,
      [`${prefixCls}-dangerous`]: !!danger,
      'btn-bubbly': isBubbly,
    },
    className,
    rootClassName,
    button?.className,
  );

  const fullStyle: React.CSSProperties = { ...button?.style, ...customStyle };

  const iconCls = classNames(customClassNames?.icon, button?.classNames?.icon);
  const iconStyle: React.CSSProperties = {
    ...(styles?.icon ?? {}),
    ...(button?.styles?.icon ?? {}),
  };

  const iconNode =
    icon && !innerLoading ? (
      <IconWrapper prefixCls={prefixCls} className={iconCls} style={iconStyle}>
        {icon}
      </IconWrapper>
    ) : (
      <LoadingIcon existIcon={!!icon} prefixCls={prefixCls} loading={!!innerLoading} />
    );

  const kids = children || children === 0 ? <span>{children}</span> : null;

  const handleClickLink = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>,
  ) => {
    const { navigate } = linkButtonRestProps;
    if (innerLoading || mergedDisabled) {
      e.preventDefault();
    } else {
      navigate?.(e);
    }
  };

  if (linkButtonRestProps.href !== undefined) {
    return (
      <button
        {...linkButtonRestProps}
        className={classNames(btnCls, {
          [`${prefixCls}-disabled`]: mergedDisabled,
        })}
        type="button"
        style={fullStyle}
        onClick={handleClickLink}
        disabled={mergedDisabled} // Add the "disabled" attribute
        ref={ref as React.Ref<HTMLButtonElement>}
      >
        {iconNode}
        {kids}
      </button>
    );
  }

  return (
    <button
      {...rest}
      type={htmlType}
      className={btnCls}
      style={fullStyle}
      onClick={handleClick}
      disabled={mergedDisabled}
      ref={ref as React.Ref<HTMLButtonElement>}
    >
      {iconNode}
      {kids}
    </button>
  );
};

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  InternalButton,
) as CompoundedComponent;

Button._BUTTON = true;

export default Button;
