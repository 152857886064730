/* eslint-disable no-unused-vars */
import React from 'react';
import { FaInfoCircle, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { StyleTextArea } from './style';

type TextAreaProps = {
  id?: string;
  className?: string;
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  state?: 'success' | 'warning' | 'error';
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  texthelp?: string;
  border?: boolean;
  readOnly?: boolean;
  name?: string;
};

const TextArea: React.FC<TextAreaProps> = (props): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    id,
    name,
    className,
    placeholder,
    value = '',
    border = true,
    disabled,
    readOnly,
    onChange,
    state,
    texthelp,
  } = props;

  const chkState = (state: string | undefined) => {
    if (state === 'success') {
      return <FaCheckCircle className="absolute right-5 top-5 text-success" />;
    } else if (state === 'warning') {
      return <FaInfoCircle className="absolute right-5 top-5 text-warning" />;
    } else if (state === 'error') {
      return <FaTimesCircle className="absolute right-5 top-5 text-error" />;
    } else {
      return '';
    }
  };
  return (
    <StyleTextArea className={className}>
      <textarea
        onChange={onChange}
        placeholder={placeholder}
        className={`textarea ${border === true ? 'textarea-bordered' : ''}${
          state ? ` textarea-${state}` : ''
        }`}
        id={id ?? name}
        name={name}
        disabled={disabled}
        readOnly={readOnly}
        {...props}
      />
      {chkState(state)}
      {texthelp !== undefined ? (
        <div className="text-muted mt-1 text-sm">{texthelp}</div>
      ) : (
        ''
      )}
    </StyleTextArea>
  );
};

export default TextArea;
