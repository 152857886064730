import * as React from 'react';

export interface CSPConfig {
  nonce?: string;
}

export type DirectionType = 'ltr' | 'rtl' | undefined;

export interface ComponentStyleConfig {
  className?: string;
  style?: React.CSSProperties;
}

export type PopupOverflow = 'viewport' | 'scroll';

export interface ConfigConsumerProps {
  direction?: DirectionType;
  skeleton?: ComponentStyleConfig;
}

export const ConfigContext = React.createContext<ConfigConsumerProps>({});

export const { Consumer: ConfigConsumer } = ConfigContext;
