import styled from 'styled-components';

export const StyleHeader = styled.div`
  transition:
    left 0.3s ease,
    right 0.3s ease;
  display: block;

  &.header-temp-wrap {
    position: absolute;
    top: auto;
    bottom: auto;
    width: 100%;
    z-index: 1;
  }
  .header-temp {
    padding-top: 14px;
    padding-bottom: 14px;
    position: relative;
  }
  .header-temp__menu {
    display: none;
    @media only screen and (min-width: 768px) {
      display: flex;
      align-items: center;
    }
    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .header-temp__menu-item {
    .header-temp__menu-link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      cursor: pointer;
      &.active {
        color: #fff;
      }
    }
  }
`;
