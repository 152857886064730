import * as React from 'react';
import { ButtonProps } from '.';

export interface ComponentStyleConfig {
  className?: string;
  style?: React.CSSProperties;
}

export interface ButtonConfig extends ComponentStyleConfig {
  classNames?: ButtonProps['classNames'];
  styles?: ButtonProps['styles'];
}

export interface ConfigConsumerProps {
  button?: ButtonConfig;
}

export const ConfigContext = React.createContext<ConfigConsumerProps>({});

export const { Consumer: ConfigConsumer } = ConfigContext;
