import styled from 'styled-components';

export const StyleSelect = styled.div`
  position: relative;
  .select {
    &-container {
      .search-box {
        padding: 5px;
        background-color: #eee;
        input {
          width: 100%;
          box-sizing: border-box;
          padding: 5px;
          border: 1px solid #ccc;
        }
      }
      &.disabled {
        user-select: none;
        cursor: default;
        touch-action: none;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    &-input {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      display: flex;
      flex-shrink: 1;
      height: 3rem;
      align-items: center;
      justify-content: space-between;
      user-select: none;
      text-align: left;
      border-bottom: 1px solid #000000;
      position: relative;
    }
    &-menu {
      position: absolute;
      transform: translateY(4px);
      width: 100%;
      overflow: auto;
      max-height: 150px;
      background-color: #fff;
      z-index: 99;
      box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.13);
    }
    &-item {
      width: 100%;
      flex-shrink: 1;
      height: 3rem;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      font-size: 1rem;
      line-height: 1.5rem;
      cursor: pointer;
      align-items: center;
      display: flex;
      background-color: #ffffff;
      &:hover {
        background-color: #9fc3f870;
      }
      &.selected {
        background-color: #e8f7ff;
        color: #6a6b81;
      }
    }
    &-tags {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
    }
    &-tag-item {
      background-color: #ddd;
      padding: 2px 4px;
      display: flex;
      align-items: center;
    }
    &-tag-close {
      display: flex;
      align-items: center;
    }
  }
  &.select-sm,
  &.select-lg {
    padding-left: 0;
    padding-right: 0;
  }
  &.select-lg {
    height: 3.5rem;
    min-height: 3.5rem;
    .select-input {
      height: 3.5rem;
    }
  }
  &.select-sm {
    height: 2.5rem;
    min-height: 2.5rem;
    .select-input {
      height: 2.5rem;
    }
  }
  &.select-success,
  &.select-warning,
  &.select-error,
  &.select-info {
    .select-input {
      border-width: 2px;
    }
  }
  &.select-success {
    .select-input {
      border-color: hsl(var(--su));
    }
  }
  &.select-warning {
    .select-input {
      border-color: hsl(var(--wa));
    }
  }
  &.select-error {
    .select-input {
      border-color: hsl(var(--er));
    }
  }
  &.select-info {
    .select-input {
      border-color: hsl(var(--in));
    }
  }
  .select-selected-placeholder {
    color: #adabb9;
  }
`;
