/* eslint-disable no-unused-vars */
import React from 'react';
import { FaInfoCircle, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { StyleInput } from './style';
import classNames from 'classnames';

const Input: React.FC<{
  id?: string;
  name?: string;
  type?: 'text' | 'email' | 'password' | 'search';
  size?: 'md' | 'lg';
  className?: string;
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  autoComplete?: string;
  state?: 'success' | 'warning' | 'error';
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  texthelp?: string;
}> = ({
  id,
  size = '',
  type = 'text',
  className,
  placeholder,
  value,
  disabled,
  onChange,
  autoComplete,
  state,
  texthelp,
  name,
}) => {
  const rootCls = classNames(
    {
      disabled: disabled === true,
    },
    className,
  );
  const inputCls = classNames({
    [`input-${state}`]: state !== undefined,
    [`input-${size}`]: size === 'lg',
    [`input-default`]: size === undefined,
  });

  const chkState = (state: string | undefined) => {
    if (state === 'success') {
      return <FaCheckCircle className="absolute right-5 text-success" />;
    } else if (state === 'warning') {
      return <FaInfoCircle className="absolute right-5 text-warning" />;
    } else if (state === 'error') {
      return <FaTimesCircle className="absolute right-5 text-error" />;
    } else {
      return '';
    }
  };

  return (
    <StyleInput className={rootCls}>
      <input
        disabled={disabled}
        value={value}
        id={id ?? name}
        // defaultValue={value}
        name={name}
        onChange={onChange}
        type={type}
        placeholder={placeholder}
        className={inputCls}
        autoComplete={autoComplete}
      />
      {chkState(state)}
      {texthelp !== undefined ? (
        <div className="text-muted mt-1 text-sm">{texthelp}</div>
      ) : (
        ''
      )}
    </StyleInput>
  );
};

export default Input;
