/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs().tz('Asia/Bangkok');

interface DateRenderProps {
  type?: 'short' | 'long';
  showTime?: boolean;
  date: Dayjs | string | Date | number;
  dateSeparator?: string;
}
const DateRender = ({
  type,
  showTime,
  date: dat,
  dateSeparator = '/',
}: DateRenderProps) => {
  const checkTypeDate = typeof dat === 'number' ? dayjs.unix(dat) : dayjs(dat);
  if (!checkTypeDate.isValid()) return null;
  const renderDate = (type: 'short' | 'long' | undefined) => {
    switch (type) {
      case 'short':
        return checkTypeDate.format(
          `DD${dateSeparator}MM${dateSeparator}YYYY${showTime === true ? ' - HH:mm' : ''}`,
        );
      case 'long':
        return checkTypeDate.format(`DD MMMM YYYY${showTime === true ? ' - HH:mm' : ''}`);
      default:
        return checkTypeDate.format(`DD MMM YYYY${showTime === true ? ' - HH:mm' : ''}`);
    }
  };

  return renderDate(type);
};

export default DateRender;
