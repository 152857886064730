/* eslint-disable @typescript-eslint/naming-convention */
import { CSSProperties, ReactNode, createElement } from 'react';
import styled from 'styled-components';

type TagVariants =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'span'
  | 'small'
  | 'strong'
  | 'em'
  | 'li';

export type colors =
  | 'primary'
  | 'primary-content'
  | 'secondary'
  | 'secondary-content'
  | 'accent'
  | 'accent-content'
  | 'neutral'
  | 'neutral-content'
  | 'info'
  | 'success'
  | 'warning'
  | 'error'
  | 'base-100'
  | 'base-200'
  | 'base-300'
  | 'base-content'
  | '--btn-text-case';

export type color = 'black' | 'white' | colors;

interface TypographyProps {
  tag: TagVariants;
  children: ReactNode;
  style?: CSSProperties;
  className?: string;
}

export const DynamicTypography = styled(
  ({ tag, className, children, ...props }: TypographyProps) =>
    createElement(tag, { className, ...props }, children),
)``;
